/* TODO(Jonathon): Replace the dark grey with proper on-brand color */
.footer {;
    background-color: #fff;
    color: #222222;
    border-top: 1px #222222 solid;
    text-align: left;
}

/*
 * Attempts to replicate the company title style for the Algolia blog.
 * See: https://blog.algolia.com/ (top of page, or footer)
 */
.footer h5 span {
    background-color: #1b1e21;
    color: #ffffff;
    padding: 2%;
    border-radius: 10%;
    font-size: 0.75em;
}

.footer-bg, .list-group-item {
    background-color: #fff;
}

.list-group-item {
    border: none;
}

.footer-bg, .list-group-item a {
    color: #222222;
    text-decoration: none;
}

.footer-bg, .list-group-item a:hover {
    color: blue !important;
}

/*
 * react-bootstrap overrides
 */
.card-header {
    background-color: #ffffff;
}

.footer .row:first-child {
    padding-top: 2%;
    padding-bottom: 2%;
}

@media only screen and (max-width: 600px) {
    .footer .row:first-child {
        padding-top: 5%;
    }
}

.four-o-four-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10%; /* Required to visually center the content */
}

.four-o-four-card {
    margin-left: 20px;
    background: none;
    border: none;
    text-align: left;
    font-size: larger;
}

.four-o-four-card .card-text a {
    color: orange;
}

[data-amplify-authenticator] {
    --amplify-colors-brand-primary-10: var(--primary);
    --amplify-colors-brand-primary-80: var(--primary);
    --amplify-colors-brand-primary-90: var(--primary);
    --amplify-colors-brand-primary-100: var(--primary);
    --amplify-components-button-primary-background-color: var(--primary);
    --amplify-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

[data-amplify-authenticator] [data-state=inactive] {
    background-color: #F8F9F9 !important;
    color: #444 !important;
}

.App .amplify-button[data-variation='primary'] {
    background: var(--primary);
}

.App .amplify-tabs {
    color: var(--primary);
}

.App .amplify-tabs-item {
    color: var(--primary);
}

.App .amplify-tabs-item[data-state=active] {
    color: var(--primary);
    border-color: var(--primary);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

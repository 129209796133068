div .nav-fill {
    width: 100%;
}

.navbar {
    /*background: linear-gradient(180deg,#0b3e71,rgba(11,65,118,.5));*/
    /*background-color: rgba(11,65,118,.8);*/
    background: white;
    background-repeat: repeat-x;
}
